import React, { useState } from "react";
import { typeSelectOptions, decorationSelctOptions, structureSelectOptions } from '../../constants/selectedOption'
import CreatableSelect from 'react-select/creatable';
import { ActionMeta, MultiValue } from "react-select"
import { ProjectDetailStores } from "../../stores/projectDetailStores";

export interface IProjectDescriptionCard {
  header: string;
  preview: boolean;
  project: ProjectDetailStores
}

type SelectOptionType = { label: string, value: string }

const ProjectDescriptionCard: React.FC<IProjectDescriptionCard> = ({
  header,
  preview,
  project,
}) => {
  const initSelctOption: SelectOptionType = {
    label: "defalut",
    value: 'defalut'
  }

  const selectedStrData = () => {
    switch (header) {
      case "Project name":
        return project.ProjectName
      case "Project owner":
        return project.ProjectOwner
      case "Status":
        return project.Status
      case "Project value":
        return project.ProjectValue
      case "Complete":
        return project.Completion
      case "Area":
        return project.Area
      case "Structure":
        return project.Structure
      case "Decoration":
        return project.DecorationMaterial
      case "Type":
        return project.Type
    }
  }

  const selectArrData = () => {
    switch (header) {
      case "Structure":
        return project.Structure
      case "Decoration":
        return project.DecorationMaterial
      case "Type":
        return project.Type
    }
  }

  const [refresh, setRefresh] = useState(false)

  const convert_type_data = (list: [string] | string[], refresh: boolean) => {
    const list_option: [SelectOptionType] = [initSelctOption];
    for (let i of list) {
      const initSelctOption: SelectOptionType = {
        label: i,
        value: i
      }
      list_option.push(initSelctOption)

    }
    list_option.shift()
    return list_option
  }

  function get_value_list(selction: MultiValue<SelectOptionType>) {
    const list_value: [string] = [''];
    for (let value of selction) {
      list_value.push(value.value);
    }
    list_value.shift()
    return list_value
  }

  const setArrInputHandler = (newSelections: MultiValue<SelectOptionType>, actionMeta: ActionMeta<SelectOptionType>) => {
    project.changeArrType(header, get_value_list(newSelections))
    setRefresh(!refresh)
  }

  function toArr(list: string[]) {
    if (list.length <= 1) {
      return list
    }
    let exportString = ""
    list.forEach((element, index) => {
      exportString = exportString + element.toString() + (index + 1 === list.length ? "" : " , ")
    });
    return exportString
  }

  function selectedInputType(refresh: boolean) {
    switch (header) {
      case "Decoration": case "Type": case "Structure":
        return <CreatableSelect
          isMulti
          options={header === "Type" ? typeSelectOptions : header === "Decoration" ? decorationSelctOptions : structureSelectOptions}
          onChange={setArrInputHandler}
          isSearchable={true}
          isClearable
          className='text-center'
          defaultValue={convert_type_data(selectArrData()!, refresh)}
        />
      case "Status":
        return <select className='w-full pt-3 pr-5 border-2 border-solid border-gray-300 text-center p-1' id="Status" name="Status"
          placeholder="Add Status [Complete/Draft]"
          onChange={(event) => { project.changeStrType(header, event.target.value) }} defaultValue={project.Status}>
          <option>Complete</option>
          <option>Draft</option>
        </select>
      case "Design":
        return <div className="w-fit text-white text-xl font-light">Gateway Achitects</div>
      default:
        return <input id="Value" name="Value" type="text" placeholder={`add Value`}
          className={"w-full h-full pt-3 pr-5 border-2 border-solid border-gray-300 text-center p-1 "}
          onChange={(event) => {
            project.changeStrType(header, event.target.value)
            setRefresh(!refresh)
          }}
          defaultValue={selectedStrData()} />
    }
  }

  const showPreview = () => {
    return (
      <div className="w-fit text-white text-xl font-light">
        {header === "Structure" || header === "Decoration" || header === "Type" ? toArr(selectArrData()!) :
          header === "Design" ? "Gateway Achitects" : selectedStrData()
        }
      </div>
    )
  }


  return (
    <div className="grid gap-4 grid-cols-2 my-10 mx-10">
      <div className="w-fit text-white text-xl">{header}</div>
      <form>
        {preview ? showPreview() : selectedInputType(refresh)}
      </form>
    </div>
  );
};

export default ProjectDescriptionCard;
