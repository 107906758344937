import { action, makeObservable, observable } from 'mobx'
import { detailForm } from '../models/ProjectDetail'

export class ProjectDetailStores implements detailForm {
    Area: string[];
    Completion: number;
    DecorationMaterial: string[];
    ImagePath: string[];
    ProfileImage: string;
    ProjectName: string;
    ProjectOwner: string;
    ProjectValue: number;
    Status: string;
    Structure: string[];
    Type: string[];
    ProjectId: string
    NewImagePath: File[]
    NewProfileImage: File

    constructor(props: detailForm) {
        this.ProjectName = props.ProjectName
        this.Area = props.Area
        this.Completion = props.Completion
        this.DecorationMaterial = props.DecorationMaterial
        this.ImagePath = props.ImagePath
        this.ProfileImage = props.ProfileImage
        this.ProjectOwner = props.ProjectOwner
        this.ProjectValue = props.ProjectValue
        this.Status = props.Status
        this.Structure = props.Structure
        this.Type = props.Type
        this.ProjectId = props.ProjectId
        this.NewImagePath = [new File(["int"], "init", {
            type: "init",
        })]
        this.NewProfileImage = new File(["int"], "init", {
            type: "init",
        })
        makeObservable(this, {
            ProjectName: true,
            Area: observable,
            Completion: observable,
            DecorationMaterial: observable,
            ImagePath: observable,
            ProfileImage: observable,
            ProjectOwner: observable,
            ProjectValue: observable,
            Status: observable,
            Structure: observable,
            Type: observable,
            ProjectId: observable,
            changeArrType: action,
            changeStrType: action
        })
    }

    changeStrType(dataset: string, data: string) {
        switch (dataset) {
            case "Project name":
                this.ProjectName = data
                break;
            case "Project owner":
                this.ProjectOwner = data
                break;
            case "Status":
                this.Status = data
                break;
            case "Project value":
                this.ProjectValue = Number(data)
                break;
            case "Complete":
                this.Completion = Number(data)
                break;
            case "Area":
                this.Area = data.split(",")
                break;
        }
    }

    changeArrType(dataset: string, data: string[]) {
        switch (dataset) {
            case "Structure":
                this.Structure = data
                break;
            case "Decoration":
                this.DecorationMaterial = data
                break;
            case "Type":
                this.Type = data
                break;
        }
    }

    changeImagePath(data: File[]) {
        this.NewImagePath = data
    }

    changeProfileImage(data: File) {
        this.NewProfileImage = data
    }
}
