import { FC, useEffect, MouseEvent } from 'react';
import UploadMainPicture from '../ImageComponent/UploadMainPicture';
import ProjectDescriptionSection from './ProjectDescriptionSection';
import '../../assets/styles/ProjectDescription.scss'
import UploadMinorPicture from "../ImageComponent/UploadMinorPicture"
import { useState } from 'react';
import { ProjectDetailStores } from '../../stores/projectDetailStores';
import axios from 'axios';
import { fixedSidebar } from '../../functions/sidebarConfig';
import Swal from 'sweetalert2'

interface Props {
  project: ProjectDetailStores
  data_th: ProjectDetailStores
  token: string
  method: string
  page: string
}

function convertToArr(data: string[]): string[] {
  const exprotData: string[] = [];
  data.forEach(element => {
    exprotData.push(element);
  });
  return exprotData
}

const ProjectDescription: FC<Props> = ({ project, data_th, token, method, page }) => {
  const [preview, setPreview] = useState<boolean>(false)
  const [language, setLanguage] = useState<string>("English")

  const images = page === "edit" ? [
    'https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80',
    'https://picsum.photos/id/1015/1000/600/',
    'https://picsum.photos/id/1019/1000/600/'
  ] : [""];

  const createSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    let formData = new FormData()
    if (project.NewImagePath[0].name !== "init") {
      project.NewImagePath.forEach(file => {
        formData.append("ImagePath", file)
      })
    }
    if (project.NewProfileImage.name !== "init") {
      formData.append("ProfileImage", project.NewProfileImage)
    }
    formData.append("requests", JSON.stringify({
      "en-us": {
        "ProjectId": project.ProjectId,
        "ProjectName": project.ProjectName,
        "ProjectOwner": project.ProjectOwner,
        "Area": convertToArr(project.Area),
        "Type": convertToArr(project.Type),
        "ProjectValue": project.ProjectValue,
        "Completion": project.Completion,
        "Structure": convertToArr(project.Structure),
        "DecorationMaterial": convertToArr(project.DecorationMaterial),
        "Status": project.Status
      }
    }))
    try {
      await axios.post("http://127.0.0.1:8000/project/create", formData, config).then(() => {
        Swal.fire({
          title: 'Create Complete',
          text: 'Create Project content successful.',
          icon: 'success',
        });
      })
    } catch (error) {
      alert("Fail to submit form!")
    }
    // window.location.href = '/projects';
  }

  const handleSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const imageFormData = new FormData()
    if (project.NewProfileImage.name === "init") {
      imageFormData.append("ProfileImage", project.ProfileImage)
    }

    if (project.NewImagePath[0].name === "init") {
      project.ImagePath.forEach(file => {
        imageFormData.append("ImagePath", file)
      })
    }
    else {
      project.NewImagePath.forEach(file => {
        imageFormData.append("ImagePath", file)
      })
    }

    const output = {
      content: {
        "en-us": {
          Area: convertToArr(project.Area),
          Completion: Number(project.Completion),
          DecorationMaterial: convertToArr(project.DecorationMaterial),
          ProjectName: project.ProjectName,
          ProjectOwner: project.ProjectOwner,
          ProjectValue: Number(project.ProjectValue),
          Status: project.Status,
          Structure: convertToArr(project.Structure),
          Type: convertToArr(project.Type),
          ProjectId: String(project.ProjectId),
        }
      },
      Img: {
        ProjectId: project.ProjectId,
        ImagePath: convertToArr(project.ImagePath),
        ProfileImage: project.ProfileImage,
      }
    }
    try {
      await axios({
        method: method,
        url: `http://127.0.0.1:8000/project/update`,
        data: output,
        headers: {
          'Content-Type': 'application/json',
          'gw-access-token': `${token}`
        }
      }).then(() => {
        Swal.fire({
          title: 'Update Complete',
          text: 'Update Project content successful.',
          icon: 'success',
        });
      })
    } catch (error) {
      alert("Failed to submit form, Please try again")
    }
    try {
      await axios.put(`http://127.0.0.1:8000/project/${project.ProjectId}/image`, imageFormData, config)
    }
    catch (error) {
      alert("Failed to submit photo!")
    }
    // window.location.href = '/projects';
  }

  useEffect(() => {
    fixedSidebar()
  }, [])

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    setPreview(!preview)
  }


  return (
    <div className='ml-[230px] overflow-hidden'>
      <div className="relative">
        <div
          id="header"
          className={`absolute right-0 bottom-0 z-10 text-white sm:text-[9vw] mobile:text-[8vw]  md:text-[11vw] ${preview ? "" : "hidden"}`}
        >
          {project.ProjectName}
        </div>
        <UploadMainPicture project={language === "English" ? project : data_th} imagePath={page === "edit" ? project.ProfileImage : "https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"} preview={preview} />
      </div>
      <div className="grid grid-cols-2 bg-black">
        <p className='text-center my-auto text-white'>Current Language: </p>
        <select className='grid w-full pt-3 pr-5 border-2 border-solid border-gray-300 text-center place-content-center' id="Language" name="Language"
          placeholder="Add Status [Complete/Draft]"
          onChange={() => { setLanguage(language === "English" ? "Thai" : "English") }} value={language}>
          <option>English</option>
          <option>Thai</option>
        </select>
      </div>
      <div className="bg-[#1B1B1B] pb-5">
        <ProjectDescriptionSection project={language === "English" ? project : data_th} preview={preview} language={language} />
        <p className={`w-full text-red-500 text-xl text-center text-bold font-semibold ${preview ? "hidden" : ""}`}>Cick the image to remove </p>
        <div className='bg-[#202020] pt-16 pb-6'>
          <UploadMinorPicture project={language === "English" ? project : data_th} imagePath={project.ImagePath} preview={preview} site={"project"} />
        </div>
      </div>
      <div className='fixed bottom-5 right-5 z-50'>
        <button type="button" className="w-28 bg-lime-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-6" onClick={handleClick}>{`${preview ? "Edit" : "Previews"}`}</button>
        <button type="submit" className="w-28 ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-6" onClick={page === "add" ? createSubmit : handleSubmit}>Save</button>
      </div>
    </div>
  );
};

export default ProjectDescription;
