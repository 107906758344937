import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from 'react-pro-sidebar'; // https://github.com/azouaoui-med/react-pro-sidebar
import '../assets/styles/sidebar.scss';
import { Link } from 'react-router-dom';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import LogoutIcon from '@mui/icons-material/Logout';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import GroupIcon from '@mui/icons-material/Group';


const Sidebar = () => {

  return (
    <ProSidebar id='allSidebar'>
      <SidebarHeader></SidebarHeader>
      <SidebarContent>
        <Menu>
          <MenuItem icon={<WarehouseIcon />}>
            Home Page <Link to="homepage" />
          </MenuItem>
          <MenuItem icon={<ApartmentIcon />}>
            Projects <Link to="projects" />
          </MenuItem>
          <MenuItem icon={<DomainAddIcon />}>
            Add Project <Link to="projects/add" />
          </MenuItem>
          <MenuItem icon={<GroupIcon />}>
            Users <Link to="users" />
          </MenuItem>
        </Menu>
      </SidebarContent>
      <SidebarFooter>
        <Menu>
          <MenuItem icon={<LogoutIcon/>}>
            Logout <Link to="/logout" />
          </MenuItem>
        </Menu>   
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Sidebar;
