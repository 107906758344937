import axios from 'axios'
import { useEffect, ChangeEvent, MouseEvent } from 'react'
import { useState } from 'react'
import { closeSidebar } from '../functions/sidebarConfig'
import { FormProps } from '../interfaces/formProps'
const RegisterForm = () => {

  const [password1, setPassword1] = useState<string>("")
  const [password2, setPasssword2] = useState<string>("")

  const handlePassowrd1 = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword1(e.target.value)
  }

  const handlePassowrd2 = (e: ChangeEvent<HTMLInputElement>) => {
    setPasssword2(e.target.value)
  }

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const form: HTMLFormElement = document.querySelector('#registerForm')!;
    const formData = new FormData(form)
    if (password1 !== password2) {
      alert("Your password doesn't correct")
      return
    }

    const data: FormProps['authForm'] = {
      email: formData.get("email")! as string,
      password: password1
    }

    try {
      axios({
        method: 'post',
        url: "http://127.0.0.1:8000/auth/register",
        data: data,
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      alert("Your account has been created")
      window.location.href = "../login"
    } catch (error) {
      alert("faild to submit form")
    }
  }

  useEffect(() => {
    closeSidebar()
  }, [])

  return (
    <div className="bg-gray-600 h-screen flex flex-col overflow-hidden ">
      <form id="registerForm">
        <div className="container max-w-sm max-h-screen mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm p-4 sm:p-6 lg:p-20 dark:bg-gray-800 dark:border-gray-700">
            <h1 className="mb-8 text-3xl text-center font-medium text-gray-900 dark:text-white">Sign up</h1>

            <input
              type="text"
              className="block border border-grey-light w-full p-3 rounded mb-4"
              name="email"
              id="email"
              placeholder="Email"
            />

            <input
              type="password"
              className="block border border-grey-light w-full p-3 rounded mb-4"
              name="password"
              placeholder="Password"
              onChange={handlePassowrd1} />
            <input
              type="password"
              className="block border border-grey-light w-full p-3 rounded mb-4"
              name="confirm_password"
              placeholder="Confirm Password"
              onChange={handlePassowrd2}
            />

            <button
              type="submit"
              className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={handleSubmit}>Create Account</button>
            <div className="text-white mt-6 text-center">
              Already have an account?
              <a className="text-blue-700 hover:underline dark:text-blue-500" href="../login/">
                Log in
              </a>.
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default RegisterForm