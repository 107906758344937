import { Transition } from 'react-transition-group';
import { useEffect, useState, CSSProperties, FormEvent } from 'react';
import { Direction } from '../../functions/constant/constant';
import useScrollDirection from '../../functions/useScrollDirection';

import '../../assets/styles/Home.scss';
import axios from 'axios';
import UploadMinorPicture from "../ImageComponent/UploadMinorPicture";
import { defaultSidebar } from '../../functions/sidebarConfig';
import HomeDetailStores from '../../stores/homeDetailStores';
import Swal from 'sweetalert2'
import { adminApi, api } from '../../api';
import { HomeDetail } from '@/api/generated';


type homeProps = {
  props: HomeDetailStores;
  thProps: HomeDetailStores;
  token: string;
}

const Home = ({ props, thProps, token }: homeProps) => {
  const [pageContent, setPageContent] = useState<HomeDetail>()
  const [preview, setPreview] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [language, setLanguage] = useState<string>("en-us")
  const [isShowDescription, setShowDescription] = useState(true);
  const { directionUpDown, setDirectionUpDown } =
    useScrollDirection(10);
  const [imagePath, setImagePath] = useState<string[]>(pageContent && pageContent.ImagePath && pageContent.ImagePath.length > 0 ? props.ImagePath : ["/images/mockHomeImage.jpeg",
  'https://picsum.photos/id/1015/1000/600/'])


  useEffect(() => {
    defaultSidebar()
  })

  useEffect(() => {
    if (directionUpDown === Direction.Down) {
      setShowDescription(true);
    } else if (directionUpDown === Direction.Up) {
      setShowDescription(false);
    }
  }, [directionUpDown]);

  useEffect(() => {
    const imagePath = props.ImagePath.length > 0 ?Array.from(props.ImagePath).map(item => item) : ["/images/mockHomeImage.jpeg",
      'https://picsum.photos/id/1015/1000/600/']
    props.changeImage(imagePath)
    setImagePath(imagePath)
  }, [props, thProps])

  useEffect(() => {
    getHomePageData()
  }, [language])

  const getHomePageData = async () => {
    try {
      const response = await api.componentsSchemaLanguageGet(language, 4);
      setPageContent(response.data as HomeDetail)
      console.log(response.data)
    } catch (error) {
      alert("Fail to fetch data!!!. Please try it again later.")
    } 
  }

  const defaultStyles = {
    transition: `opacity 300ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles: { [x: string]: CSSProperties | undefined } = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  const toggleShowDescription = () => {
    setShowDescription(!isShowDescription);
    if (isShowDescription) {
      setDirectionUpDown(Direction.Up);
    } else {
      setDirectionUpDown(Direction.Down);
    }
  };

  const closeDescriptionButton = (
    <div className="relative mr-[15rem] text-center z-50">
      <button onClick={toggleShowDescription} disabled={!isShowDescription}>
        <img
          src="/blackArrowIcon.svg"
          className="w-10 h-10"
          alt="close-button"
        />
      </button>
    </div>
  );

  const previewHandle = () => {
    setPreview(!preview)
  }

  const submitHandle = async (event: FormEvent<HTMLFormElement>) => {
    const imageFormData = new FormData()

    if (props.NewImagePath[0].name === "init") {
      props.ImagePath.forEach(file => {
        imageFormData.append("ImagePath", file)
      })
    }
    else {
      props.NewImagePath.forEach(file => {
        imageFormData.append("ImagePath", file)
      })
    }

    try {
      adminApi.componentsSchemaLanguagePost(4, language, token, pageContent).then(() => {
        Swal.fire({
          title: 'Update Complete',
          text: 'Update home content successful.',
          icon: 'success',
        });
      })
    } catch (error) {
      alert("Error modify home page detail!.")
    }

    try {
      axios({
        method: "post",
        url: "http://127.0.0.1:8000/components/4/image/en-us",
        data: imageFormData,
        headers: {
          'content-type': 'multipart/form-data',
          'gw-access-token': `${token}`,
        }
      })
    } catch (error) {
      alert("Error modify home page detail!.")
    }

  }

  const selectedLanguage = (
    <form>
      <select id='language' name='language' className='bg-black text-white rounded-md h-10 w-20 text-center'
        onChange={(event) => {
          event.preventDefault()
          setLanguage(event.target.value)
        }}>
        <option value="en-us">English</option>
        <option value="th-th">Thai</option>
      </select>
    </form>
  );

  return (
    <div>
      <form onSubmit={submitHandle}>
      <div className='ml-3'>
        <div className="absolute right-0 z-10 pt-20 font-semibold text-white mt-10 mr-2 sm:mr-20">
          <p className="text-[2.75rem] leading-[4.5rem] sm:text-[4rem]">
            GATEWAY
            <br />
            ARCHITECTS
          </p>
          <hr
            style={{
              color: '#DE4B52',
              backgroundColor: '#DE4B52',
              border: 0,
              height: 6,
            }}
          />
          {preview ?
            <p className="font-normal text-2xl mt-2">{pageContent?.Slogan}</p> :
            <div>
              <input 
                className="font-normal text-2xl mt-2 text-black w-full p-2 rounded-lg"
                value={pageContent?.Slogan}
                onChange={(e) => {
                  e.preventDefault();
                  setPageContent({
                    ...pageContent,
                    Slogan: e.target.value,
                  })
                }} 
                required
              />
            </div>}
        </div>
        <div className="absolute bottom-0 left-0 right-0 z-50 ml-[230px] text-center">
          <button onClick={toggleShowDescription} disabled={isShowDescription}>
            <img
              src="/whiteArrowIcon.svg"
              className="w-10 h-10"
              alt="open-button"
            />
          </button>
        </div>
        <Transition in={isShowDescription} timeout={300}>
          {(state) => (
            <div
              className="absolute w-full z-20 bottom-0 bg-white"
              style={{
                ...defaultStyles,
                ...transitionStyles[state],
              }}
            >
              {closeDescriptionButton}
              <div className="flex pb-8 mt-4 mx- sm:mx-10">
                <div className="border-l-6 h-{100%} border-gateway-red-1 hidden sm:block mr-5" style={{
                }}>
                </div>
                <div className='w-full'>
                  {preview ?
                    <div className='break-words text-md sm:text-lg w-10/12 '>
                      {pageContent?.AboutDescription}
                    </div>
                    :
                    <div>
                      <textarea
                        className="text-md sm:text-lg w-10/12 rounded-sm border-[1px] border-gray-700" value={pageContent?.AboutDescription}
                        onChange={(e) => {
                          props.changeData("aboutus", e.target.value)
                          setRefresh(!refresh)
                        }}
                        required
                      />
                    </div>}
                  <p className="text-2xl text-blue-button-1" >Read more</p>
                </div>
              </div>
            </div>
          )}
        </Transition>
        <UploadMinorPicture imagePath={imagePath} preview={preview} site={"home"} project={props} />
      </div>
      <div className='fixed top-1 right-6'>
        {selectedLanguage}
      </div>
      <div className='fixed bottom-5 right-5 z-50'>
        <button type="button" className="w-28 bg-lime-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-6"
          onClick={previewHandle}>{`${preview ? "Edit" : "Previews"}`}</button>
        <button type="submit" className="w-28 ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-6"> Save</button>
      </div>
      </form>
    </div>

  );
};

export default Home;
