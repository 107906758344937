import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Projects from './views/Projects';
import AddProject from './views/AddProject';
import Sidebar from './components/sidebar';
import EditProject from './views/EditProject'
import Login from './views/Login'
import Logout from './views/Logout'
import App from './App';
import Register from './views/Register';
import HomePage from './views/HomePage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className="h-screen w-screen flex ">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="grow">
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/register" element={<Register />} />
            <Route path="/projects" element={<Projects />}></Route>
            <Route path="/projects/edit/" element={<EditProject />}></Route>
            <Route path="/projects/add/" element={<AddProject />}></Route>
            <Route path="/homepage" element={<HomePage />}></Route>
          </Routes>
        </div>
      </div>  
    </BrowserRouter>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
