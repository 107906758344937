import Cookies from 'universal-cookie'

function checkExpire() {
    const cookie = new Cookies()
    const currentTime = new Date()
    if (!cookie.get('token')) {
        alert(`Please login`)
        window.location.href = '/login'
    }
    if (cookie.get('time') < currentTime) {
        cookie.remove('token')
        cookie.remove('time')
        alert("Please login")
        window.location.href = '/login'
    }
}

export default checkExpire;