import { FC, ChangeEvent, useState } from 'react'
import { ProjectDetailStores } from '../../stores/projectDetailStores'

interface Props {
    project: ProjectDetailStores | undefined
    imagePath: string
    preview: boolean
}

const UploadMainPicture: FC<Props> = ({imagePath, preview, project }) => {
    
    const [mainImage, setMainImage] = useState<string>(imagePath);
    const [selectedImages, setSelectedImages] = useState<string[]>([mainImage]);

    const mainImageHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const fileUrlArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file))
            const fileArray = Array.from(e.target.files).map((file) => file)
            setSelectedImages(fileUrlArray)
            setMainImage(fileUrlArray[0]);
            project && project.changeProfileImage(fileArray[0])
        }
    }    

    const renderPhotos = (source: string[]) => {
        return source.map((photo) => {
            return (
            <div className={`w-full relative z-0 h-[100vh] overflow-hidden ${preview?"opacity-100":"opacity-60"} `} id="main-img">
                <img
                    className="object-cover h-full w-full"
                    src={photo}
                    alt="Background"
                />
            </div>)
        })
    }

    return (
        <div className='w-full z-0 h-[100vh]'>
            <div className='flex w-full h-full items-center justify-center'>
                <label htmlFor="file-input" id="label-file-input" className={`absolute z-30 bg-gray-900 text-white p-2 border-2 border-solid rounded-lg text-center max-h-16 max-w-xs font-bold ${preview?"hidden":""}`} >
                    Choose an image File
                </label>
                <input type="file" id="file-input" accept="image/*" className='hidden' onChange={mainImageHandleChange}/>
            <div className='w-full'>
                {renderPhotos(selectedImages)}
            </div>
            </div>
        </div>
    )
}

export default UploadMainPicture