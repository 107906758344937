import { useEffect } from 'react'
import Cookies from 'universal-cookie'

const Logout = () => {

  function removecookie() {
    const cookie = new Cookies()
    cookie.remove("token")
    alert("You are logout")
    window.location.href = '/login'
  }

  useEffect(() => {
    removecookie()
  }, [])
  

  return (
    <div></div>
  )
}

export default Logout