import { ChangeEvent, useState, MouseEvent, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { closeSidebar } from '../functions/sidebarConfig';
import Swal from 'sweetalert2'
import { adminApi } from '../api';
import { User } from '@/api/generated';

const LoginForm = () => {
  const [remember, setRemember] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const cookie = new Cookies();

  const handleRemember = (event: ChangeEvent<HTMLInputElement>) => {
    setRemember(event.target.checked);
  };

  useEffect(() => {
    closeSidebar();
    const currentTime = new Date();
    if (!cookie.get('token')) {
      if (cookie.get('time') < currentTime) {
        cookie.remove('token');
        cookie.remove('time');
      }
    } else {
      window.location.href = '../projects';
    }
  }, []);

  const handleLogin = async (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    e.preventDefault()
    try {
      const user: User = {
        email,
        password
      }
      const tokenRes = await adminApi.authLoginPost(user)
      const token = tokenRes.data.token
      if (token === undefined) {
        Swal.fire({
          title: 'Unknown error',
          icon: 'error',
        });
        return;
      }
      await setCookie(token);
      window.location.href = '../projects';
    } catch (e: any) {
      if (e.response.status === 400) {
        Swal.fire({
          title: 'Incorrect username or password',
          icon: 'error',
        });
      }
    }
  };

  const setCookie = async (token: string) => {
    let time: Date = new Date();
    time.setDate(time.getDate() + 1);
    cookie.set('token', token, {
      path: '/',
      maxAge: remember ? 2592000 : 86400,
    });
    if (
      cookie.get('token') !== cookie.get('tokenChecker') ||
      !cookie.get('tokenChecker')
    ) {
      cookie.set('expire', time);
    }
    cookie.set('tokenChecker', token);
  };

  return (
    <div className="bg-gray-600 m-w-full h-full grid place-content-center">
      <div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm p-4 sm:p-6 lg:p-20 dark:bg-gray-800 dark:border-gray-700">
        <form className="space-y-8" id="loginForm">
          <h3 className="text-xl font-medium text-gray-900 dark:text-white text-center">
            Sign in
          </h3>
          <div>
            <label
              htmlFor="email"
              className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
            >
              Your email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
            >
              Your password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="••••••••"
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex items-start">
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="remember"
                  aria-describedby="remember"
                  type="checkbox"
                  className="bg-gray-50 border border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                  checked={remember}
                  onChange={handleRemember}
                />
              </div>
              <div className="text-sm ml-3">
                <label
                  htmlFor="remember"
                  className="font-medium text-gray-900 dark:text-gray-300"
                >
                  Remember me
                </label>
              </div>
            </div>
          </div>
          <button
            className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            type="submit"
            onClick={handleLogin}
          >
            Login to your account
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
