import {
  FC,
  ChangeEvent,
  useState,
  useEffect,
  BaseSyntheticEvent,
  ReactNode,
} from 'react';
import ImageGallery from 'react-image-gallery';
import FullscreenBackground from './FullScreenBackGround';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { ProjectDetailStores } from '../../stores/projectDetailStores';
import HomeDetailStores from '@/stores/homeDetailStores';

interface Props {
  imagePath: string[];
  preview: boolean;
  site: string;
  project?: ProjectDetailStores | undefined | HomeDetailStores;
}

type ImageForm = {
  original: string;
  thumbnail: string;
};

const UploadMainPicture: FC<Props> = ({
  imagePath,
  preview,
  site,
  project,
}) => {
  const [render, setRender] = useState<ReactNode>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [minorImage, setMinorImage] = useState<string[]>(
    Array.from(imagePath).map((url) => url)
  );
  const [selectedImages, setSelectedImages] = useState<string[]>(minorImage);
  const [isShowGalleryNav, setShowGalleryNav] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const initImageForm: ImageForm = {
    original: '',
    thumbnail: '',
  };

  const minorImageHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const fileUrlArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      const fileArray = Array.from(e.target.files).map((file) => file);
      setSelectedImages((prevImages) => prevImages.concat(fileUrlArray));
      setMinorImage(fileUrlArray);
      project && project.changeImagePath(fileArray);
    }
  };

  const handleResize = () => {
    if (window.innerWidth < 816) {
      setShowGalleryNav(false);
    } else {
      setShowGalleryNav(true);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const convertMinorImageForm = (images: string[]) => {
    let newImageForm: [ImageForm] = [initImageForm];
    for (let i of images) {
      if (i !== '') {
        const image: ImageForm = {
          original: i,
          thumbnail: i,
        };
        newImageForm.push(image);
      }
    }
    newImageForm.shift();
    return newImageForm;
  };

  async function fetchImageAsFile(url: string): Promise<File> {
    const response = await fetch(url);
    const blob = await response.blob();
    const fileName = url.substring(url.lastIndexOf('/') + 1);
    return new File([blob], fileName, { type: blob.type });
  }

  async function onClickHandles(e: BaseSyntheticEvent) {
    if (!preview && window.confirm('You want to delete this image')) {
      let newSelectedImage: string[] = selectedImages.filter((element) => {
        return element !== e.target.src;
      });
      
      const filePromises = newSelectedImage.map((src) =>
        fetchImageAsFile(src)
      );
      const files = await Promise.all(filePromises);
      project && project.changeImagePath(files);
      setSelectedImages(newSelectedImage);
      setMinorImage(newSelectedImage);
    }
  }

  const deleteImage = () => {
    if (window.confirm('Do you sure to delete this image')) {
      let currentImage: string[] = selectedImages.splice(currentIndex, 1);
      setSelectedImages(currentImage);
      setMinorImage(currentImage);
      setRefresh(true);
    }
  };

  useEffect(() => {
    const renderPhotos = (source: string[], refresh: boolean) => {
      setRender(
        <div
          className={`${preview ? 'opacity-100' : 'opacity-25'}`}
          id="minor-img"
        >
          {site === 'project' ? (
            <ImageGallery
              items={convertMinorImageForm(minorImage)}
              thumbnailPosition={'bottom'}
              showPlayButton={isShowGalleryNav}
              showNav={false}
              autoPlay={false}
              showFullscreenButton={false}
              slideDuration={600}
              onClick={onClickHandles}
            />
          ) : (
            <FullscreenBackground
              imgUrl={source}
              duration={10}
              isSlideShowActive={true}
              callback={setCurrentIndex}
            />
          )}
        </div>
      );
    };
    renderPhotos(imagePath, refresh);
  }, [refresh, imagePath, preview, minorImage]);

  return (
    <div className="w-full z-0 h-[100vh]">
      <div>
        <div
          className={`flex ${
            site === 'home' ? 'w-5/12 h-4/6' : 'w-full h-full'
          } items-center justify-center mb-10 ${
            site === 'home' ? 'absolute' : ''
          }`}
        >
          <label
            htmlFor="file-minor-input"
            id="label-file-input"
            className={`${
              preview ? 'hidden' : 'visible'
            } z-50 bg-gray-900 text-white p-2 border-2 border-solid rounded-lg text-center 
                    ${
                      site === 'home'
                        ? 'h-[50px] w-[230px] grid place-content-center text-lg'
                        : ''
                    } font-bold`}
          >
            Choose an image File
          </label>
          <input
            type="file"
            id="file-minor-input"
            multiple
            accept="image/*"
            className="hidden"
            onChange={minorImageHandleChange}
          />
        </div>
        <div
          className={`fixed b-0 r-0 w-[3rem] h-[3rem] text-black text-xl z-50 ${
            preview ? 'hidden' : ''
          }`}
        >
          <IconButton size="large" color="error" onClick={deleteImage}>
            <DeleteIcon fontSize="large" />
          </IconButton>
        </div>
        <div className="w-full">{render}</div>
      </div>
    </div>
  );
};

export default UploadMainPicture;
