import { action, makeObservable, observable } from "mobx";
import { homeForm } from "../interfaces/homeProps";

export default class HomeDetailStores implements homeForm {
    AboutDescription: string;
    ImagePath: string[];
    Language: string;
    Slogan: string;
    _id: Object;
    NewImagePath: File[];
    constructor(props: homeForm | undefined) {
        this.AboutDescription = props?.AboutDescription?props.AboutDescription:""
        this.ImagePath = props?.ImagePath?props!.ImagePath:[]
        this.Language = props?.Language?props!.Language:""
        this.Slogan = props?.Slogan?props!.Slogan:""
        this.NewImagePath = [new File(["int"], "init", {
            type: "init",
        })]
        this._id = props?._id?props!._id:{}
        makeObservable(this, {
            AboutDescription: observable,
            ImagePath: observable,
            Language: observable,
            Slogan: observable,
            _id: observable,
            changeImage: action,
            changeData: action,
            changeImagePath: action
        })     
    }

    changeData(header:string, data:string) {
        switch(header) {
            case "aboutus":
                this.AboutDescription = data
                break;
            case "Language":
                this.Language = data
                break;
            case "slogan":
                this.Slogan = data
                break;
        }
    }

    changeImage(data: string[]) {
        this.ImagePath = data
    }

    changeImagePath(data: File[]) {
        this.NewImagePath = data
    }
}