import {useEffect} from 'react'
import {FormProps} from '../interfaces/formProps'
import ProjectDescription  from '../components/projectCompoent/ProjectDescription'
import { useLocation} from 'react-router-dom'
import checkExpire from '../functions/checkExpireToken'
import { ProjectDetailStores } from '../stores/projectDetailStores'

const EditProject = () => {
  const data = useLocation().state as Array<object>;
  const eng_data = data[0] as FormProps['detailForm']
  const th_data = data[1] as FormProps['detailForm']
  const token = JSON.stringify(data[2])

  useEffect(() => {
    checkExpire()
  }, [])
  
  return (
    <ProjectDescription project={new ProjectDetailStores(eng_data[0])} data_th={new ProjectDetailStores(th_data[0])} token={token} method={"put"} page={"edit"}/>
  )
}

export default EditProject