import { useEffect, useState} from 'react';
import Card from '../components/Card'
import '../assets/styles/allproject.scss'
import { FormProps } from '../interfaces/formProps'
import axios from 'axios'
import checkExpire from '../functions/checkExpireToken'
import Cookies from 'universal-cookie';
import { defaultSidebar } from '../functions/sidebarConfig';

const Projects = () => {
  const token = new Cookies().get("token")

  let th_mock_data: FormProps["detailForm"] = [{
        Area: ["4", "300 SQ.M.", "5 ชั้น"],
        Completion: 2021,
        DecorationMaterial: ["ไม้เทียม", "อะลูมิเนียม", "กระจก"],
        ImagePath: ["https://www.dotproperty.co.th/en/houses-for-sale/bangkok", "https://www.dotproperty.co.th/en/houses-for-sale/bangkok"],
        ProfileImage: "https://www.dotproperty.co.th/en/houses-for-sale/bangkok",
        ProjectName: "บริษัท เกรทเวย์ อาคิเทค",
        ProjectOwner: "บริษัท วิศวกรรมที่ปรึกษา หนึ่ง แปด จำกัด",
        ProjectValue: 7000000,
        Status: "เสร็จสิ้น",
        Structure: ["โครงสรัางเหล็ก"],
        Type: ["ตึก", "ออฟฟิช"],
        ProjectId: "2"},
    
      {
      Area: ['4', '300 SQ.M.', '5 floors'],
      Completion: 2020,
      DecorationMaterial: ["แผ่นไม้เทียม, ปูนปลาสเตอร์, กระจก"],
      ImagePath: ["https://www.dotproperty.co.th/en/houses-for-sale/bangkok", "https://www.dotproperty.co.th/en/houses-for-sale/bangkok"],
      ProfileImage: "https://www.dotproperty.co.th/en/houses-for-sale/bangkok",
      ProjectName: "ที่พักอาศัยกองบัญชาการกองทัพไทย",
      ProjectOwner: "กองบัญชาการกองทัพไทย",
      ProjectValue: 457000000,
      Status: "ตัวอย่าง",
      Structure: ["โครงสรัางเหล็ก"],
      Type: ["ตึก", "ออฟฟิช", "ทาสี"],
      ProjectId: "1"
  }]

  const [data, setData] = useState<FormProps['detailForm']>();

  const fetchToken = async () => {
    checkExpire()
  }

  useEffect(() => {
    const fetch = async() => {
      await fetchToken()
    }
    fetch()
    getData()
    defaultSidebar()
  }, [])

  const getData = async () => {
    try {
      const res = await axios.get("http://127.0.0.1:8000/project/en-us/data");
      setData(res.data);
    }
    catch (e) {
      alert("Failed to fetch data.")
    }
  }

  let count = 0;

  return (
    <div className="overflow-scroll h-full mt-5">
      <div className="header grid grid-cols-4">
        <p></p>
        <p>eng</p>
        <p>th</p>
        <p>fillter</p>
      </div>
      <hr/>
      <div className="grid">
        {data?.map((item, index) => {
          let th_data = null; 
          if (count === 0) {
            th_data = th_mock_data[0]
          }
          else {
            th_data = th_mock_data[1]
          }
          count += 1;
          return <Card key={index} props={[item]} imgUrl={item.ProfileImage}  th_props={[th_data]} token={token}/>
        })}
      </div>
    </div>
  );
    }

export default Projects;
