import React from "react";
import { ProjectDetailStores } from "../../stores/projectDetailStores";
import ProjectDescriptionCard from './ProjectDescriptionCard';

interface IProjectDescriptionSection {
  project: ProjectDetailStores;
  preview: boolean;
  language: string
}

const ProjectDescriptionSection: React.FC<IProjectDescriptionSection> = ({
  project, preview, language
}) => {

  const descriptionRow = (
    header1: string,
    header2: string,
    showUnderLine: boolean
  ) => {
    return (
      <>
        <div className="grid md:grid-cols-2">
          <ProjectDescriptionCard
            header={header1}
            preview={preview}
            project={project}
          />
          {header2 !== '' && (
            <>
              <hr className="h-0.5 bg-black border-black md:hidden"></hr>
              <ProjectDescriptionCard
                header={header2}
                preview={preview}
                project={project}
              />
            </>
          )}
        </div>
        {showUnderLine && <hr className="h-0.5 bg-black border-black"></hr>}
      </>
    );
  };

  return (
    <div className="relative ml-auto mr-auto bg-[#1B1B1B]">
      <div className="w-fit ml-auto mr-auto content-center">
        {descriptionRow(
          'Project name',
          'Area',
          true
        )}
        {descriptionRow(
          'Design',
          'Project owner',
          true
        )}
        {descriptionRow(
          'Structure',
          'Decoration',
          true
        )}
        {descriptionRow(
          'Type', 
          'Status', 
          true
        )}
        {descriptionRow(
          'Project value', 
          'Complete', 
          true
        )} 
      </div>
    </div>
  );
};

export default ProjectDescriptionSection;
