import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import '../../assets/styles/FullscreenBackground.scss'

export interface IFullscreenBackground {
  imgUrl: string[];
  duration: number;
  isSlideShowActive: boolean;
  callback: Dispatch<SetStateAction<number>>
}

const FullscreenBackground: React.FC<IFullscreenBackground> = ({ imgUrl, duration, isSlideShowActive, callback }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    if (isSlideShowActive) {
      setTimeout(() => {
        if (currentIndex >= imgUrl.length - 1) {
          setCurrentIndex(0)
        } else {
          setCurrentIndex(currentIndex + 1)
        }
        callback(currentIndex)
      }, duration * 1000)
    }
  })

  return (
    <div className="w-full relative z-0 h-[100vh] flex overflow-hidden">
      {imgUrl.map((img, index) => {
        return (
          <img
            key={index}
            id={index === currentIndex ? "image-active" : "image-deactive"}
            className={"object-cover h-full w-full absolute"}
            src={img}
            alt="Background"
          />)
      })}
    </div>
  );
};

export default FullscreenBackground;
