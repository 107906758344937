export const typeSelectOptions = [
    {value: "Building", label: "Building"},
    {value: "Office", label: "Office"}
]

export const structureSelectOptions = [
    {value: "Steel Structure", label: "Steel Structure"},
    {value: "Reinforced Concrete", label: "Reinforced Concrete"},
    {value: "Post-Tensioned", label: "Post-Tensioned"}
]

export const decorationSelctOptions = [
    {value: "artificial wood", label: "artificial wood"},
    {value: "aluminum", label: "aluminum"},
    {value: "glass", label: "glass"},
    {value: "plaster", label: "plaster"},
    {value: "painting", label: "painting"},
    {value: "artificial wood slats", label: "artificial wood slats"},
    {value: "Texture", label: "Texture"},
    {value: "aluminum composite", label: "aluminum composite"},
    {value: "smooth plaster", label: "smooth plaster"}
]