import { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import Home from '../components/homepageComponent/HomePageDetail'
import checkExpire from '../functions/checkExpireToken'
import { homeForm } from '../interfaces/homeProps'
import HomeDetailStores from '../stores/homeDetailStores'

const HomePage = () => {
  let token = new Cookies().get("token")
  const [homeEngData] = useState<homeForm>()
  const [homeThData] = useState<homeForm>()

  useEffect(() => {
    checkExpire()
  }, [])

  return (
    <Home props={new HomeDetailStores(homeEngData)} thProps={new HomeDetailStores(homeThData)} token={token}/>
  );
}

export default HomePage