import { useEffect, useState } from 'react';
import { FormProps } from '../interfaces/formProps';
import Cookies from 'universal-cookie';
import checkExpire from '../functions/checkExpireToken';
import ProjectDescription from '../components/projectCompoent/ProjectDescription';
import { ProjectDetailStores } from '../stores/projectDetailStores';
import axios from 'axios';

const AddProject = () => {
  const [data, setData] = useState<FormProps['detailForm']>();
  let token = '';

  let mock_data: FormProps['detailForm'] = [
    {
      Area: [''],
      Completion: 0,
      DecorationMaterial: [],
      ImagePath: [
        'https://images.unsplash.com/photo-1575936123452-b67c3203c357?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D',
        'https://d38b044pevnwc9.cloudfront.net/cutout-nuxt/enhancer/2.jpg',
      ],
      ProfileImage: 'https://www.dotproperty.co.th/en/houses-for-sale/bangkok',
      ProjectName: '',
      ProjectOwner: '',
      ProjectValue: 0,
      Status: '',
      Structure: [],
      Type: [],
      ProjectId: '1',
    },
  ];

  const getData = async () => {
    try {
      const res = await axios.get('http://127.0.0.1:8000/project/en-us/data');
      setData(res.data);
    } catch (e) {
      alert('Failed to fetch data.');
    }
  };

  const fetchToken = async () => {
    const cookie = new Cookies();
    checkExpire();
    token = await cookie.get('token');
  };

  useEffect(() => {
    fetchToken();
    getData();
  }, []);

  return (
    <ProjectDescription
      project={new ProjectDetailStores(mock_data[0])}
      data_th={new ProjectDetailStores(mock_data[0])}
      token={token}
      method={'post'}
      page={'add'}
    />
  );
};

export default AddProject;
